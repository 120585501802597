if (process.env.RAILS_ENV === 'test') require('@stimulus/polyfills')

import { Application } from 'stimulus'
import { definitionsFromContext } from "stimulus/webpack-helpers";

const application = Application.start()
const context = require.context("./controllers", true, /\.js$/);
const componentsContext = require.context("../components", true, /_controller\.js$/);
application.load(
  definitionsFromContext(context).concat(
    definitionsFromContext(componentsContext)
  )
);

export default application
