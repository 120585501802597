import "./tab_component.sass";

import { Controller } from 'stimulus'

export default class extends Controller {
  select(e) {
    const event = new Event('select.tab', { bubbles: true })
    this.element.dispatchEvent(event)
  }
}
