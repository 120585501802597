import debug from 'lib/debug'
import { get } from 'lib/helper'
import { Controller } from 'stimulus'

const { log, error } = debug('atoms:async')

export default class extends Controller {
  async connect() {
    const url = this.data.get('url')
    log('load %s', url)
    const res = await get(url)
    if (!res.ok) return error(res)
    const html = await res.text()
    const nodes = this.render(html)
    this.replaceWith(nodes)
  }

  replaceWith(nodes) {
    const parent = this.element.parentNode
    nodes.forEach(n => parent.insertBefore(n.cloneNode(true),this.element))
    this.element.remove()
  }

  render(html) {
    const template = document.createElement('template')
    template.innerHTML = html.trim()
    return template.content.childNodes
  }
}
