import "./audio_scraper_component.sass";

import { Controller } from 'stimulus'

export default class extends Controller {
  static get targets() {
    return ['input', 'player']
  }

  change(event) {
    event.stopPropagation()
    const value = event.target.value
    const target = this.inputTarget
    if (value) {
      if (this.playerTargets.length) {
        this.playerTarget.dataset.src = value
        this.playerTarget.dataset.active = true
      }
      target.value = value
      target.focus()
    }
  }
}
