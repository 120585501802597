import "./fieldset_component.sass";

import { toggle } from 'lib/helper'
import { Controller } from 'stimulus'

export default class extends Controller {
  static get targets() {
    return [ 'li' ]
  }
  choose(event) {
    const active = this.element.dataset.active !== 'false'
    if (active) this.close(event)
    else this.open(event)
  }

  close(event) {
    const items = this.liTargets
    const target = event.currentTarget
    toggle(items, el => el.isSameNode(target))
    toggle(this.element, false)
  }

  open(event) {
    const inputs = this.inputTargets
    const items = this.liTargets
    toggle(items, true)
    toggle(this.element, true)
  }
}
