import "./radio_group_component.sass";

import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    this.element.addEventListener('change', this.setActive.bind(this))
  }

  get radioTargets() {
    return [...this.element.querySelectorAll('input[type=radio]')]
  }

  setActive(event) {
    this.radioTargets.forEach(el => el.parentNode.dataset.active = el.checked)
  }
}
