import "./tooltip_component.sass";

import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    const active = this.element.dataset.active
    if (active === 'true') this.element.dataset.hidden = false
    this.element.addEventListener('transitionstart', this.transitionstart.bind(this))
    this.element.addEventListener('transitionend', this.transitionend.bind(this))
  }

  close() {
    this.element.dataset.active = false
  }

  transitionend() {
    const active = this.element.dataset.active
    if (active === 'false') this.element.dataset.hidden = true
  }

  transitionstart() {
    const active = this.element.dataset.active
    if (active === 'true') this.element.dataset.hidden = false
  }
}
