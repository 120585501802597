import "./linked_in_component.sass";

import { Controller } from 'stimulus'
import { post } from 'lib/helper'

export default class extends Controller {
  share(event) {
    event.preventDefault()
    const url = event.currentTarget.dataset.url
    const href = event.currentTarget.getAttribute('href')
    post(url, { type: 'linkedin' })
    window.open(href, '', 'width=560, height=320')
  }
}
