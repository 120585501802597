
// https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent

const os = {
  android: navigator.userAgent.toLowerCase().indexOf('android') > -1,
  windows: /Windows/i.test(navigator.userAgent),
  osx: /macintosh/i.test(navigator.userAgent),
  ios: !!navigator.platform && /iPad|iPhone|iPod|iOS/.test(navigator.platform),
}

const others = {
  current: Object.keys(os).find(key => os[key]),
  mobile: /Mobi|Android/i.test(navigator.userAgent),
}

export default {
  ...os,
  ...others,
}
