import "./footer_component.sass";

import { Controller } from 'stimulus'
import device from 'lib/device'
import { toggleData } from 'lib/helper'

export default class extends Controller {
  static get targets() {
    return [ 'ios', 'android' ]
  }

  connect() {
    if (device.ios)
      { toggleData(this.iosTargets, 'active', true) }
    else if (device.android)
      { toggleData(this.androidTargets, 'active', true)}
  }
}
