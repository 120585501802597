import "./properties_component.sass";

import { Controller } from "stimulus";
// import ujs from '@rails/ujs'

export default class extends Controller {
  connect() {
  }


  resetHandler(event) {
    // setTimeout(() => ujs.fire(this.element, 'submit'), 1)
  }

  ajaxHandler(event) {
    const target = event.target
    const [data, status, xhr] = event.detail

    const updateTargets = document.querySelectorAll('.komponent-preview')
    ;[].forEach.call(updateTargets, el => {
      const copy = el.cloneNode(false)
      copy.srcdoc = data
      el.srcdoc = data
      el.replaceWith(copy)
    })
  }
}
