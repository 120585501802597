import "./radio_option_component.sass";

import { Controller } from 'stimulus'

export default class extends Controller {

  static get targets() {
    return [ 'radio', 'editable' ]
  }
  get value() {
    return this.radioTarget.value
  }

  set value(v) {
    const el = this.radioTarget
    el.value = v
    el.dispatchEvent(new Event('change', { bubbles: true }))
  }

  onBlur(event) {
    this.element.dataset.focus = false
  }

  onFocus(event) {
    this.element.dataset.focus = true
  }

  onInput(event) {
    this.value = this.editableTarget.textContent
    this.setEmptyState()
  }

  onKeypress(event) {
    const s = String.fromCharCode(event.which)
    if (!s.match(/^[0-9]+$/)) event.preventDefault()
  }

  onPaste(event) {
    event.preventDefault()
  }

  setFocus() {
    const el = this.editableTarget
    const selection = window.getSelection()
    const range = document.createRange()
    selection.removeAllRanges()
    range.selectNodeContents(el)
    range.collapse(false)
    selection.addRange(range)
    el.focus()
  }

  setEmptyState() {
    this.element.dataset.empty = this.value === ''
  }

  connect() {
    this.setEmptyState()
  }

  setActive(event) {
    const el = this.radioTarget
    el.checked = true
    el.dispatchEvent(new Event('change', { bubbles: true }))
    this.setEmptyState()
  }
}
