import "./popup_component.sass";

import { Controller } from 'stimulus'
import { lockyOn } from 'dom-locky'
import { removeIOSRubberEffect } from 'lib/helper'
import EventBus from 'lib/events'

export default class extends Controller {
  static get targets() {
    return [ 'body' ]
  }

  connect() {
    const checkHashAndThenOpen = hash => { if (hash === this.hash) this.open() }

    const urlParams = new URLSearchParams(window.location.search)
    const popup = urlParams.get('popup')
    if (popup) checkHashAndThenOpen(popup)
    EventBus.on('modals--popup-component--popup-component:open', checkHashAndThenOpen)

    if (this.bodyTargets.length) removeIOSRubberEffect(this.bodyTargets[0])
  }

  get hash() {
    return this.data.get('hash')
  }

  setQueryParameter() {
    const params = new URLSearchParams(location.search)
    params.set('popup', this.hash)
    history.replaceState(null, null, '?' + params + location.hash)
  }

  removeQueryParameter() {
    const params = new URLSearchParams(location.search)
    params.delete('popup')
    const paramString =  '?' + params + location.hash
    history.replaceState(null, null, (paramString !== '?') ? paramString : location.pathname)
  }

  open() {
    this.unlock = lockyOn(this.element)
    this.setQueryParameter()
    this.element.dataset.active = 'true'
    this.closeHandlerReference = this.closeHandler.bind(this)
    document.addEventListener('keydown', this.closeHandlerReference)
  }

  close() {
    if (this.unlock) this.unlock()
    this.removeQueryParameter()
    this.element.dataset.active = 'false'
    if (this.closeHandlerReference) document.removeEventListener('keydown', this.closeHandlerReference)
  }

  closeHandler() {
    if (event.keyCode == 27 || event.type == 'click') this.close()
  }

  stopPropagation(event) {
    event.stopPropagation()
  }
}
