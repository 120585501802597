import "./piq_detail_header_component.sass";

import { Controller } from 'stimulus'

export default class extends Controller {
  static get targets() {
    return [ 'context' ]
  }

  openModal(event) {
    const target = event.target
    const isLink = target.matches('a') || target.closest('a')
    if (!isLink) this.contextTargets.forEach(el => el.classList.add('is-active'))
  }
}
