import "./copy_to_clipboard_component.sass";

import { Controller } from 'stimulus'
import device from 'lib/device'

export default class extends Controller {
  static get targets() {
    return [ 'value', 'message' ]
  }

  connect() {
    if (device.ios) this.valueTarget.readonly = true
  }

  showMessage() {
    const message = this.messageTarget
    message.dataset.active = true
    window.setTimeout(() => message.dataset.active = false, 2500)
  }

  copy() {
    this.valueTarget.select()

    try {
      return document.execCommand('copy')
    } catch (e) {
      return false
    }
  }

  iosCopy() {
    var text = document.createElement('textarea')

    text.style.position = 'fixed'
    text.style.top = '0'
    text.style.left = '0'
    text.style.opacity = '0'
    text.style.fontSize = '16px' // prevents zoom!
    text.contentEditable = true
    text.readOnly = false
    text.value = this.valueTarget.value

    document.body.appendChild(text)

    const range = document.createRange()
    range.selectNodeContents(text)
    const selection = window.getSelection()
    selection.removeAllRanges()
    selection.addRange(range)
    text.setSelectionRange(0, 999999)

    try {
      return document.execCommand('copy')
    } catch(e) {
      alert(e)
      return false
    } finally {
      document.body.removeChild(text)
    }
  }

  clickInput(event) {
    const target = event.currentTarget
    if (device.ios) {
      this.clickHandler()
    } else {
      target.select()
    }
  }

  clickButton() {
    const success = device.ios ? this.iosCopy() : this.copy()
    if (success) this.showMessage()
  }
}
