import "./app_store_opener_component.sass";

import { Controller } from 'stimulus'
import device from 'lib/device'

export default class extends Controller {
  static get targets() {
    return [ 'android', 'ios' ]
  }

  connect() {
    if (device.android) {
      this.androidTarget.dataset.active = true
    } else if (device.ios) {
      this.iosTarget.dataset.active = true
    }
  }
}
