import "./radio_component.sass";

import { Controller } from 'stimulus'

export default class extends Controller {
  static get targets() {
    return [ 'input' ]
  }

  connect() {}

  triggerOnChange(event) {
    const checked = this.inputTarget.checked
    if (checked) this.inputTarget.dispatchEvent(new Event('change', { bubbles: true }))
  }
}
