import { Controller } from 'stimulus'
import EventBus from 'lib/events'

export default class extends Controller {
  choose(event) {
    event.preventDefault()
    const href = event.currentTarget.href
    EventBus.emit('komponent-breadcrumbs:choose', href)
  }
}
