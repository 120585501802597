import "./wysiwyg_component.sass";

import { Controller } from 'stimulus'
import { watchAttribute } from 'lib/watcher'

export default class extends Controller {
  static get targets() {
    return [ 'video' ]
  }

  connect() {
    this.element.addEventListener('transitionend', this.play.bind(this))

    // create a 'active' event for outside use
    const event = document.createEvent('CustomEvent')
    event.initCustomEvent('active', true, true, null)
    watchAttribute(this.element, 'data-active', mutation => {
      const active = mutation.target.dataset.active
      this.element.dispatchEvent(event)
    })
  }

  play() {
    this.videoTarget.play()
  }
}
