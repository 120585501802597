import "./wrapper_component.sass";

import { Controller } from 'stimulus'
import { active, filter } from 'lib/helper'

export default class extends Controller {
  static get targets() {
    return [ 'tab' ]
  }

  connect() {
    console.log("Hello, Stimulus!", this.element)

    const hash = location.hash.slice(1)
    if (hash) active(this.tabTargets, el => el.id === hash)
  }

  select(event) {
    const target = event.target
    location.hash = target.id
    active(this.tabTargets, el => el.isSameNode(target))
  }
}
