import "./podcast_component.sass";

import Controller from '../popup_component/popup_component_controller.js'
import device from 'lib/device'
import { active, filter } from 'lib/helper'



export default class extends Controller {
  static get targets() {
    return [ 'app', 'cloud', 'page', 'detailView', 'tab', 'backButton' ]
  }

  connect() {
    super.connect()
    this.showCurrentPage()
    this.activateAppTab()
    this.podcastUrl = this.element.dataset.podcastUrl
  }

  close() {
    super.close()
    this.index = 0
    this.activateAppTab()
  }

  next() {
    this.index++
  }

  previous() {
    this.index--
  }

  activateAppTab() {
    active(this.cloudTargets, false)
    active(this.appTargets, filter('tab', device.current || 'osx'))
  }

  activateCloudTab() {
    active(this.appTargets, false)
    active(this.cloudTargets, true)
  }

  chooseApp(event) {
    this.currentApp = event.currentTarget.dataset.value
    this.index = 2
  }

  openApp(replacement) {
    const link = this.podcastUrl.replace(/(.*)\:\/\/(.*)/, replacement)
    return link
  }

  openAppHandler(event) {
    const link = this.data.get('link')
    if (link !== 'undefined') window.open(link, '_blank')
  }

  showDetailView() {
    this.detailViewTargets
      .forEach(el => {
        el.classList.toggle('active', el.dataset.name === this.currentApp)

        if (el.dataset.name === this.currentApp) {
          const link = this.openApp(el.dataset.url)
          this.data.set('link', link)
        }
      })
  }

  get currentApp() {
    return this.data.get('currentApp') || 'rss'
  }

  set currentApp(value) {
    this.data.set('currentApp', value)
    this.showDetailView()
  }

  showCurrentPage() {
    this.backButtonTargets
      .forEach(el => el.classList.toggle('active', this.index !== 0))

    this.pageTargets
      .forEach((el, i) => el.classList.toggle('active', this.index === i))


  }
  get index() {
    return parseInt(this.data.get('page') || '0')
  }

  set index(value) {
    this.data.set('page', value)
    this.showCurrentPage()
  }
}
