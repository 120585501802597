
export const toggleData = (nodes, attribute, value) =>
  nodes.forEach(el => el.dataset[attribute] = value)

export const active = (nodes, condition) =>
  nodes.forEach(el => el.dataset.active = (typeof condition === 'function') ? condition(el) : condition)

export const toggle = (nodes, condition) =>
  ('forEach' in nodes ? nodes : [nodes]).forEach(el => active([el], condition))

export const filter = (...values) => {
  return el => {
    const targets =  el.dataset
      .target
      .split(' ')
      .map(el => el.split('.')[1])
      .filter(el => values.includes(el))
      .length
    return !!targets
  }
}

// TODO:
// can be replaced with Rails.csrfToken(),
// if rails-ujs is included
export const csrfToken = () => {
  const token = document.querySelector('meta[name=csrf-token]')
  return token.getAttribute('content')
}

// from https://medium.com/actualize-network/sending-post-requests-in-rails-5-1-without-jquery-ff89f6f80487
export const post = (url, payload) =>
  fetch(url, {
    method: 'post',
    body: JSON.stringify(payload),
    headers: { 'X-CSRF-TOKEN': csrfToken(), 'Content-Type': 'application/json' },
    credentials: 'same-origin'
  })

export const get = (url) =>
  fetch(url, {
    method: 'get',
    headers: { 'X-CSRF-TOKEN': csrfToken(), 'Content-Type': 'application/json' },
    credentials: 'same-origin'
  })

// https://blog.christoffer.online/2015-06-10-six-things-i-learnt-about-ios-rubberband-overflow-scrolling/
export const removeIOSRubberEffect = (target) => {
  target.addEventListener( "touchstart", function () {
    var top = target.scrollTop, totalScroll = target.scrollHeight, currentScroll = top + target.offsetHeight

    if ( top === 0 ) {
      target.scrollTop = 1
    } else if ( currentScroll === totalScroll ) {
      target.scrollTop = top - 1
    }
  })
}
