import "./podcast_component.sass";

import { Controller } from 'stimulus'
import EventBus from 'lib/events'

export default class extends Controller {
  connect() {}

  openHandler(event) {
    event.preventDefault()
    const popup = event.currentTarget.dataset.key
    EventBus.emit('modals--popup-component--popup-component:open', popup)
  }
}
