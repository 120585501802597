import "./radio_component.sass";

import { Controller } from 'stimulus'

export default class extends Controller {
  static get targets() {
    return [ 'radio' ]
  }
  connect() {
    if (this.radioTarget.checked) this.element.dataset.active = true
  }
}
