import "./sidebar_component.sass";

import { Controller } from 'stimulus'
import EventBus from 'lib/events'

export default class extends Controller {
  static get targets() {
    return [ 'navItem', 'updateLink' ]
  }

  connect() {
    this.showNextNavItem(this.path)
    EventBus.on('komponent-breadcrumbs:choose', this.breadcrumbs.bind(this))

    window.addEventListener('popstate', event => {
      const path = window.location.pathname
      this.highlightNavItem(path)
      // console.log('1sidebar pushstate', event.state)
      // console.log('2sidebar pushstate', path)
    })
  }

  breadcrumbs(href) {
    const url = new URL(href)
    const pathname = url.pathname
    const navItem = this.navItemTargets.filter(el => el.dataset.path === pathname)
    if (navItem[0] && navItem[0].dataset.active !== 'true') this.showNextNavItem(pathname)
  }

  get path() {
    return this.data.get('path')
  }

  openDir(event) {
    const t = event.currentTarget
    const p = t.dataset.path
    this.showNextNavItem(p)
  }

  showNextNavItem(path) {
    let current,next
    this.navItemTargets
      .forEach(el => {
        const p = el.dataset.path
        const a = el.dataset.active === "true"
        if (a) current = { el: el, path: p, lvl: p.split('/').length }
        if (p === path) next = { el: el, path: p, lvl: p.split('/').length }
      })

    if (current) {
      const deeper = current.lvl < next.lvl
      current.el.dataset.active = false
      current.el.dataset.animate = deeper ? 'centerToLeft' : 'centerToRight'
      next.el.dataset.animate = deeper ? 'rightToCenter' : 'leftToCenter'
    }

    next.el.dataset.active = true
  }

  highlightNavItem(path) {
    this.updateLinkTargets
      .forEach(el => el.classList.toggle('active', path === el.getAttribute('href')))
  }

  onAjax(event) {
    const target = event.target
    const [data, status, xhr] = event.detail
    const t = document.querySelector('[data-update-target=component]')
    const href = event.target.getAttribute('href')
    this.highlightNavItem(href)
  }
}
