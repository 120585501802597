
import debug from 'debug'

export default namespace => {
  if (process.env.RAILS_ENV === 'development') debug.enable(`${debug.load()},${namespace}`)
  const log = debug(namespace)
  const error = debug(namespace)
  error.log = console.error.bind(console)
  return { log, error }
}
