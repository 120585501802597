
import debug from 'lib/debug'
import { EventEmitter } from 'events'

const { log } = debug('event_emitter')

class Emitter extends EventEmitter {
  emit(type, ...args) {
    log(type, ...args)
    super.emit(type, ...args)
  }
}

window.EventBus = window.EventBus || new Emitter()
export default window.EventBus
