import "./invite_box_component.sass";

import { Controller } from 'stimulus'
import { post } from 'lib/helper'

export default class extends Controller {
  async submit(event) {
    event.preventDefault()
    const target = event.currentTarget
    const action = target.getAttribute('action')
    const response = await post(action, {})
    const html = await response.text()
    this.element.outerHTML = html
  }
}
