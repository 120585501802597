
import Controller from '../../modals/popup_component/popup_component_controller.js'
import './sharing_component.sass'
import { active, post } from '../../lib/helper.js'
import device from '../../lib/device.js'

export default class extends Controller {
  static get targets() {
    return [ 'whatsapp', 'skip', 'shared' ]
  }

  connect() {
    active(this.whatsappTargets, device.mobile)
    super.connect()
  }

  share(event) {
    const target = event.currentTarget
    const type = target.dataset.type

    if (['twitter', 'facebook', 'linkedin'].indexOf(type) > -1) {
      event.preventDefault()
      const url = target.dataset.url
      const href = target.getAttribute('href')
      post(url, { type: type })
      window.open(href, '', 'width=560, height=320')
    }

    active(this.skipTargets, false)
    active(this.sharedTargets, true)
    event.currentTarget.dataset.shared = true
  }
}
