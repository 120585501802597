import "./media_type_component.sass";

import EventBus from 'lib/events'
import { toggle } from 'lib/helper'
import { Controller } from 'stimulus'

export default class extends Controller {
  static get targets() {
    return  ['audio', 'form']
  }

  connect() {
    const audio = this.audioTarget.checked
    this.showForm(audio)
  }

  change(event) {
    const audio = event.target.isSameNode(this.audioTarget)
    this.showForm(audio)
  }

  showForm(audio) {
    const form = this.formTargets
    toggle(form, audio)
  }
}
