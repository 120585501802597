import { Controller } from 'stimulus'

export default class extends Controller {
  static get targets() {
    return [ 'updateTarget' ]
  }

  connect() {
    const state = {
      html: this.element.innerHTML,
      path: window.location.pathname
    }
    history.replaceState(state, '', '')

    window.addEventListener('popstate', event => {
      if (event.state) this.innerHTML = event.state.html
    })
  }

  set innerHTML(html) {
    this.updateTargetTargets
      .forEach(el => el.innerHTML = html)
  }

  onAjax(event) {
    const target = event.target
    const [data, status, xhr] = event.detail

    if (target.dataset.target === 'komponent-sidebar.updateLink') {
      this.innerHTML = data

      const state = {
        html: data,
        path: target.getAttribute('href')
      }

      history.pushState(state,'',target.getAttribute('href'))
    }
  }
}
